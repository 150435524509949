import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Text } from '@latitude/text';
import {
  BREAKPOINT,
  COLOR
} from '@latitude/core/utils/constants';
import Layout from '@/components/layout';

export default props => (
  <Layout location={props.location}>
    <main className="navigation-spacer" css="background-color:#f8f8f8;">
      <Helmet>
        <title>Page not found | Latitude Financial Services</title>
      </Helmet>

      <Page404Content>
        <h1>Oops! Something went wrong.</h1>
        <Text>Either the page you’re after doesn’t exist or it’s been taken down. Check out the links below to find what you’re looking for.</Text>
        <img src={require("../images/page-404.svg")} alt="" />
      </Page404Content>
    </main>
  </Layout>
);

const Page404Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0 100px;

  @media (min-width: ${BREAKPOINT.LG}) {
    padding: 120px 0 140px;
  }

  h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 1.17;
    letter-spacing: -1px;
    display: block;
    color: ${COLOR.BLACK};
    margin: 0 0 16px;

    @media (min-width: ${BREAKPOINT.LG}) {
      font-size: 48px;
    }
  }

  p {
    @media (min-width: ${BREAKPOINT.LG}) {
      font-size: 18px;
    }
  }

  h1, p {
    max-width: 300px;
    text-align: center;
    @media (min-width: ${BREAKPOINT.LG}) {
      max-width: 500px;
    }
  }

  img {
    margin-top: 40px;
    max-width: 80%;
  }
`
